import React from 'react';

import BlogArticle from 'components/blog/blogArticle';
import ImageModal from 'components/ui/image-modal';
import { authorKamil as author } from 'data/authors';

import img from 'img/blog/updates/segemnts_options.png';

const Content = () => {
  return (
    <div>
      <p>
        From now, you can easily share segments with your team. What&apos;s more, you can also set
        visibility of a segment by turning on &quot;Related to website&quot; (it will be only
        visible for the currently selected website).
      </p>
      <ImageModal
        src={img}
        alt="segment sharing"
        title="Create segment and share it with your team"
      />
      <p className="mt-3" />
    </div>
  );
};

const Wrapped = BlogArticle(Content);

export const frontmatter = {
  title: 'Segment sharing',
  url: '/blog/segment-sharing/',
  description: 'Share segments with your team',
  author,
  img,
  date: '2019-04-25',
  category: 'Product Updates',
  group: 'updates',
};

export default () => (
  <Wrapped
    title={frontmatter.title}
    category={frontmatter.category}
    description={frontmatter.description}
    date={frontmatter.date}
    author={frontmatter.author}
    url={frontmatter.url}
    group={frontmatter.group}
  />
);
